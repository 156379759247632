import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"

import background from "../images/salad-hero.jpg"
import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

// styles
const Feature = styled.div`
  a {
    display: inline-block;
  }

  h1 {
    color: rgba(117, 181, 0, 1);
    transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);

    @media (max-width:640px) {
      line-height: 1.1
    }
  }
`

const FeatureBoxWrapper = styled.div`
  overflow: hidden;
`

const FeatureBox = styled.div`
  background: url(${background});
  background-position: center;
  background-size: cover;
  height: 50vh;
  margin-top: 12px;
  border-radius: 18px;
  animation: BackgroundZoomOut 1s ease;
  transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);
  overflow: hidden;
  ${'' /* transition: transform 1s; */}

  :hover {
    ${'' /* transform: scale(1.05); */}
    animation: BackgroundZoomIn 1s ease;
    overflow: hidden;

  }
`
const BackArrow = styled.div`
  width: 57px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  :hover i, :hover p {
    color: rgba(117, 181, 0, 1);
  }  

  i {
    content: "\f007";
    font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    color: rgba(0, 0, 0, .78);
    font-style: normal;
  }

  p {
    font-family: 'Alpino-Bold', 'Arial', 'Helvetica', 'sans-serif';
    font-size: 19.2px;
    margin: 0;
  }

  @keyframes BackgroundZoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes BackgroundZoomOut {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Introduction = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5vw;

  a {
    font-weight: 500;
    color: rgba(117, 181, 0, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ProjectOverViewWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectOverview = styled.div`
`
const ProjectDetailsWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectDetails = styled.div`
  hr {
    opacity: 0;

    @media (max-width:640px) {
      opacity: 1;
      padding-top: 24px;
    }
  }

  @media (max-width:640px) {
    margin-top: 24px;
  }
`

const ProjectDescription = styled.div`
  p {
    margin-right: 15%;

  a {
    font-weight: 500;
    color: rgba(117, 181, 0, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

    @media (max-width:640px) {
      margin-right: auto;
    }
  }

  button {
    font-family: Alpino-Black;
    padding: 8px 20px;
    font-size: 32px;
    color: white;
    background: rgba(60, 200, 215, 1);
    border: none;
    font-weight: 700;
    border-radius: 8px;
    margin-top: 10px;
    outline: none;
    cursor: pointer;
    transition: 1s cubic-bezier(0.01, 0.8, 0.2, 1);
  }

  button:hover {
    background: black;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .25);
    transform: translateY(-3px);
  }

  img {

    @media (max-width:640px) {
      width: 100%;
    }
  }
`

const ImageDuo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5%;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  img {
    width: 100%;
  }
`

const ScrollOnUp = styled.div`
    display: block;

    :hover i, :hover p {
        color: rgba(60, 200, 215, 1);
    }
`
const ProjectWrapper = styled.div`
  max-width: 1344px;
  margin: 0 auto;
`

const sd = () => (
    <Layout>
      <Seo title="Gardening App" />
        <Helmet>
            <script src="https://kit.fontawesome.com/077761ae24.js" crossOrigin="anonymous"></script>
        </Helmet>
        <div className="Hero">
        <div id="top"/>
        <ProjectWrapper>
            <Feature>
            <Link to="/work">
                <BackArrow>
                <i className="fas fa-long-arrow-alt-left"></i>
                <p>back</p>
                </BackArrow>
            </Link>
            <h1>Salad Days App</h1>
            <FeatureBoxWrapper>
                <FeatureBox />
            </FeatureBoxWrapper>
            </Feature>
            <Introduction>
            <ProjectOverViewWrapper>
                <ProjectOverview>
                <h2>Project Overview</h2>
                <h4>Salad Days is the name of a new gardening app designed to use social and peer support to explore, grow, and fail together in an urban garden. The app focused on three major concepts to increase gardening as an activity in a younger generation.</h4>
                <p>The name “Salad Days” comes from the idiomatic expression that refers to a period of inexperience, enthusiasm, and idealism usually associated with one’s youth. Grow. Ogle. Reap. Share.</p>
                <h3>Planting plan assistance with a shopping incentive</h3>
                <p>Locale-specific planting planner with an heirloom and/or open-pollinated seed recommendation system via email with discounted seed from partnered suppliers (similar to fab.com)</p>
                <h3>Visual gardening</h3>
                <p>Think selfies for your plants (plantsies). Using Instagram’s API, images would be pushed to Salad Days, where users could go in and add meta data - plant status, comments, almost like a digital journal. Other users could comment, master gardeners could suggest help. Imagery would add social incentives to keep working in the garden (or at least to remember to water your plants).</p>
                <h3>Documentation</h3>
                <p>A very simple documentation system was included when the app is rotated landscape for capturing stages of a plant, building out a personal almanac to reference seasonal timelines to plant, reap, and sow.</p>
                </ProjectOverview>
            </ProjectOverViewWrapper>
            <ProjectDetailsWrapper>
                <ProjectDetails className="projectDetails">
                <hr></hr>
                <h5 className="details">Credits</h5>
                <p className="details">This was a solo project with feedback from 12 UX Designers in Training + 1 instructor <a href="https://www.linkedin.com/in/marc-mertens/" target="_blank" rel="noreferrer">Marc Mertens</a> while at <a href="https://www.uclaextension.edu/design-arts/uxgraphic-design" target="_blank" rel="noreferrer">UCLA Extension</a>.</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Client</h5>
                <p className="details">Self-launch</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">agency</h5>
                <p className="details">Independent project</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Deliverable</h5>
                <p className="details">In a 10 week class, deliverables were 8 presentations covering insights, personas, sketches, wireframes, architecture, content-strategy, visual design, and then a final presentation.</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Goal</h5>
                <p className="details">Develop a product from problem through prototype solution practicing UX thinking.</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">RESULTS</h5>
                <p className="details">A final presentation was delivered in Pecha Kucha style (20 slides at 20 seconds each) in front of the class and about 50 mobile app evangelists. The presentation coincided with a Mobile UX Design Meetup I was co-organizing that was started by <a href="https://www.linkedin.com/in/zaidhisham/" target="_blank" rel="noreferrer">Zaid Hisham</a>.</p>
                </ProjectDetails>
                <hr></hr>
            </ProjectDetailsWrapper>
            </Introduction>
            <ProjectDescription className="projectChapter">
            <h2>Discovery</h2>
            <p>Located only three and a half miles from my home at the time, Manchester Square is a large residential neighborhood just east of LAX that is considered a food desert. Food deserts are inhabited areas where there is little or no access to healthy or fresh food. Many food deserts exist in rural residential areas, but they are prevalent in dense urban areas as well.</p>
            <h3>The Problem: Food Deserts</h3>
            <p>As part of the project I rode my bike through Manchester Square (see map for route). Fast food was abundant, but I found only one corner shop selling produce.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/salad-bike-ride.jpg"
                    alt="Map of bike ride through manchester square"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
                <StaticImage
                    src="../images/salad-sad-produce.jpg"
                    alt="produce in low supply at robert's market"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </ImageDuo>
            <div className="imgcaption">A cost analysis would have shown it was cheaper to buy dinner from a dollar meal menu than to assemble from fresh ingredients here.</div>
            <h3>Interviews & Insights</h3>
            <p>Research was done to see if an opportunity existed for a product to help an existing organization. Interviews were conducted with random subjects, as well as heads of various organizations (including <a href="http://celosangeles.ucanr.edu/" target="_blank" rel="noreferrer">UC Cooperative Extension, LA County</a>, <a href="https://foodforward.org/" target="_blank" rel="noreferrer">Food Forward</a>, and <a href="https://www.homegrowngardensla.com/" target="_blank" rel="noreferrer">Home Grown Gardens L.A.</a>) working to solve food deserts. Lessons learned were to aim for the lowest hanging fruit (literally) which would be to empower one to grow food while building a community, and include feedback loops to bridge learning curves while gardening.</p>
            <p>Further research took place attending events (and volunteering) at the <a href="https://www.lafoodbank.org/" target="_blank" rel="noreferrer">L.A. Food Bank</a>, the <a href="http://marvistafarmersmarket.org/" target="_blank" rel="noreferrer">Mar Vista Farmers Market</a>, <a href="https://www.slola.org/" target="_blank" rel="noreferrer">The Seed Library of Los Angeles</a>, <a href="http://www.thelearninggarden.org/" target="_blank" rel="noreferrer">The Learning Garden</a>, <a href="http://www.oceanviewfarms.net/" target="_blank" rel="noreferrer">Ocean View Farms</a>, <a href="https://www.chickswithknives.com/" target="_blank" rel="noreferrer">Chicks with Knives</a>, and <a href="https://gardenerd.com/" target="_blank" rel="noreferrer">Gardenerd</a>. Big insights confirmed exploration of a gardening app as people across the board emotionally connect with things that they start. Also at the supply level, I learned that the commercial seed system is broken, using heirloom seeds or self-propagating seeds enables gardeners to harvest seed thus reducing a major cost.</p>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Observations & Hypothesis</h2>
            <h3>Personas</h3>
            <p>Five personas were created based on the sampling of the Manchester Square neighborhood and 5 mile radius, which includes students at LMU and residential areas in Westchester.</p>
            <StaticImage
                src="../images/salad-personas.jpg"
                alt="sample draft of a persona"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
            />
            <div className="imgcaption">Sample persona, five were created to help develop the app.</div>
            <p>At the time of development, there were numerous planting calendar and almanac apps, which were mainly reference material encapsulated in an app interface. Gamification was a big new platform, as were photo sharing sites Instagram and Snapchat. Gardening games existed, as did what I would call mobile portals to gardening forums, however there were no products aimed at socially sharing progress and support.</p>
            <p>User stories and user flows were created to posit different ideas on how a social gardening app could benefit various personas. Extra research was done on gardening itself, including the creation of a patio garden. I planted sunflowers which continued to grow throughout the class.</p>
            <StaticImage
                src="../images/salad-user-story.jpg"
                alt="sample draft of a user story"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
            />
            <div className="imgcaption">User stories helped to dramatically focus the world of information that is gardening.</div>
            <StaticImage
                src="../images/salad-user-scenario.jpg"
                alt="sample draft of a user scenario"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
            />
            <div className="imgcaption">User scenarios where hand-drawn to explore different user flows.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Iterations</h2>
            <p>Initial concepts focused on experimenting with a SMS interface combined with emails for more information. Exploring a line of accessibility, shooting for the most ubiquitous and cost-friendly version of a product is key. It was determined that SMS, while most accessible, would limit growth to users keen on using social media.</p>
            <p>Hand drawn wireframes explored user attenuation and accounted for all elements of the product. More user stories were created during this time to prioritize features.</p>
            <StaticImage
                src="../images/salad-hand-wire.jpg"
                alt="sample wireframe sketches and user stories"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
            />
            <div className="imgcaption">Pen and paper, oh, and a floor.</div>
            <p>Taking the design further, user flow diagrams and wireframes were rebuilt in OmniGraffle and thought was given to architecture and tech requirements. The main deliverable would be a smartphone app (smartphones being slightly more accessible than tablet or desktop), with an email communication loop. As long as you could take a photo, you could use Salad Days.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/salad-user-flow.jpg"
                    alt="sample user flow diagram for user's first visit to the site"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                />
                <StaticImage
                    src="../images/salad-wireframe.jpg"
                    alt="sample wireframe"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                />
            </ImageDuo>
            <div className="imgcaption">Information architecture studies helped to clarify technical requirements.</div>
            <p>Once the general design process was in a fully iterative state, content strategy and visual design was explored. The app required use of phone in portrait and landscape mode, and in the end to increase empathy, the user could adjust a “salad bar”, essentially a visual system to communicate how a user felt about their current garden. “Low” bars could attract the attention of experienced gardeners for help. “High” bars indicated a growing experience was going well and incouraged accolades. Very complicated</p>
            <p>Language and prompts were examined. This app had to be fun, maybe even tongue in cheek, and nerdy references to gardening without judgement were necessary.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/salad-turn-it-up.jpg"
                    alt="Using two fingers on a phone"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                />
                <StaticImage
                    src="../images/salad-visual.jpg"
                    alt="Work on visual design"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                />
            </ImageDuo>
            <div className="imgcaption">How would you like to use two fingers rotating on a phone as a user? Well, the idea was partially to explore hardware inputs as well.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Terminus</h2>
            <h3>Demo</h3>
            <a href="https://projects.invisionapp.com/share/PU75TKSX#/screens" target="_blank" rel="noreferrer">
                <button type="button">View Demo</button>
            </a>
            <p>The link above goes to an Invision App prototype, best viewed on a smartphone. Because of the rotation from landscape to portrait, the prototype was served on a phone, native browser.</p>
            <p>User feedback on the app was generally positive, part of the project was to explore ux design precedences. Spool-out menus like Path, mixed with lower user-friendly two-finger touch, and rotational screens were all part of the creative solution process, and would most likely be scaled back.</p>
            <h3>Conclusions</h3>
            <p>My goal for this project was to incorporate learnings from UCLA Extension’s UX Design class into an entire app/product from research through computational design that could be demoed for feedback. Final presentations were successful with several green thumbs in the audience approving of the idea.</p>
            <p>Since this project completed, gardening apps come and go, but one product that is great is the watering control system built by Rachio. By focusing on controlling locale through proper plant hydration, a big problem of gardening is eliminated. Not low hanging fruit, as Salad Days was designed for, but a neat product nonetheless</p>
            </ProjectDescription>
            <ScrollOnUp 
            onClick={() => scrollTo('#top')}>
            <div className="ScrollToTop">
                <p>Scroll to top</p>
                <i className="fas fa-angle-up"></i>
            </div>
            </ScrollOnUp>
        </ProjectWrapper>
        </div>
    </Layout>
  )

export default sd